import "./index.scss";
import * as THREE from "three";
import { $ } from "@olmokit/dom";
import gsap from "gsap";
import { globalData } from "@olmokit/core/data";
import fragmentsShader from "./fragmentShader";
import vertexShader from "./vertexShader";

/**
 * Component: Background
 */
export function Background() {
  console.log("Background mounted.");

  let renderer, uniforms, scene, camera;

  const $fragments = $('.Background:fragment');
  $fragments.innerHTML = fragmentsShader;
  const $vertex = $('.Background:vertex');
  $vertex.innerHTML = vertexShader;

  function init() {
    const $container = $(".Background:");

    camera = new THREE.Camera();
    camera.position.z = 1;

    scene = new THREE.Scene();

    const geometry = new THREE.PlaneBufferGeometry(2, 2);

    uniforms = {
      u_time: { type: "f", value: 1.0 },
      u_resolution: { type: "v2", value: new THREE.Vector2() },
      u_mouse: { type: "v2", value: new THREE.Vector2() },
      aColor: { type: "v3", value: new THREE.Vector3(0,0,0) },
      bColor: { type: "v3", value: new THREE.Vector3(0,0,0) }
    };

    const material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: $("#vertexShader").textContent,
      fragmentShader: $("#fragmentShader").textContent
    });

    const mesh = new THREE.Mesh(geometry, material);
    mesh.name = 'backGround'; 
    scene.add(mesh);

    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);

    $container.appendChild(renderer.domElement);

    onWindowResize();
    window.addEventListener("resize", onWindowResize, false);

  }
  function onWindowResize() {
    renderer.setSize(window.innerWidth, window.innerHeight);
    uniforms.u_resolution.value.x = renderer.domElement.width;
    uniforms.u_resolution.value.y = renderer.domElement.height;
  }
  function animate() {
    requestAnimationFrame(animate);
    render();
  }
  function render() {
    uniforms.u_time.value += 0.05;
    renderer.render(scene, camera);
  }   

  function bgColorOut(color){
    this.colorOut = this.uniforms.bColor.value;
    let targetBG = this.scene.getObjectByName('backGround');
    let initialBGb = targetBG.material.uniforms.bColor.value;
    // let getvalueB = color.split(',');
    let nextBGout = new THREE.Vector3(color[0], color[1], color[2]);
    gsap.to(initialBGb, 1, {
        x: nextBGout.x,
        y: nextBGout.y,
        z: nextBGout.z,
        delay: 0,
        onUpdate: function () {
          targetBG.material.uniforms.bColor.value = initialBGb;
        }
    });
  }

  function bgColorIn(color){
    let colorIn = uniforms.aColor.value;
    let targetBG = scene.getObjectByName('backGround');
    let initialBGa = targetBG.material.uniforms.aColor.value;
    // let getvalueA = color.split(',');
    let nextBGin = new THREE.Vector3(color[0], color[1], color[2]);
    gsap.to(initialBGa, 1, {
        x: nextBGin.x,
        y: nextBGin.y,
        z: nextBGin.z,
        delay: 0,
        onUpdate: function () {
          targetBG.material.uniforms.aColor.value = initialBGa;
          // console.log(initialBGa);
        }
    });
  }  

  function bgColorOut(color){
    let colorOut = uniforms.bColor.value;
    let targetBG = scene.getObjectByName('backGround');
    let initialBGb = targetBG.material.uniforms.bColor.value;
    // let getvalueB = color.split(',');
    let nextBGout = new THREE.Vector3(color[0], color[1], color[2]);
    gsap.to(initialBGb, 1, {
        x: nextBGout.x,
        y: nextBGout.y,
        z: nextBGout.z,
        delay: 0,
        onUpdate: function () {
          targetBG.material.uniforms.bColor.value = initialBGb;
        }
    }); 
  }  

  init();
  bgColorIn(globalData.bgcolorin);
  bgColorOut(globalData.bgcolorout);
  animate();

  function extractColors(data) {
    const html = data;

    // var html = '<div><img class="img-class2" src="#" alt="Your alt here!"></div>';

    const tmpDiv = document.createElement('div');
    tmpDiv.innerHTML = html;

    const datacolorin = tmpDiv.querySelector('.bgcolorin').getAttribute('data-bgcolorin');
    const datacolorout = tmpDiv.querySelector('.bgcolorout').getAttribute('data-bgcolorout');     

    const colorin = datacolorin.split(',');
    const colorout = datacolorout.split(',');
    
    return {
      colorin: colorin,
      colorout: colorout
    }

  }

  return {
    onBefore(data) {
    },
    onAfter(data) {
      // update links active state
      // console.log(JSON.parse(data.next.html));
      bgColorIn(extractColors(data.next.html).colorin);
      bgColorOut(extractColors(data.next.html).colorout);
      animate();
    },
  };  

}
