import { $ } from "@olmokit/dom";

/**
 * Component: Datalayer
 */
export function Datalayer() {
  console.log("Datalayer mounted.");
}

function extractName(data) {
  const html = data;

  // var html = '<div><img class="img-class2" src="#" alt="Your alt here!"></div>';

  const tmpDiv = document.createElement('div');
  tmpDiv.innerHTML = html;

  const name = tmpDiv.querySelector('main').getAttribute('data-name');
  const path = tmpDiv.querySelector('main').getAttribute('data-path');
  const locale = tmpDiv.querySelector('main').getAttribute('data-locale');
  
  return {
    name: name,
    path: path,
    locale: locale
  };

}

export function DatalayerPage() {

  return {
    onAfter(pagecategory, data) {
      console.log('datalayer sent');
      var dataLayer = window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'page_view',
        'page_category': pagecategory,
        'page_name': extractName(data.next.html).name,
        'path_clean': extractName(data.next.html).path,
        'page_language': extractName(data.next.html).locale
      });
    }
  }
}

export function DatalayerProject(action, label) {

  dataLayer.push({
    "event": "click",
    "category": "project",
    "action": action,
    "label": label
  });

}

export function DatalayerService(label) {

  dataLayer.push({
    "event": "click",
    "category": "service",
    "action": 'card letter',
    "label": label
  });

}

export function DatalayerMenu(label) {

  dataLayer.push({
    "event": "click",
    "category": "menu",
    "label": label
  });

}