import "./index.scss";
import { $, $all, forEach, on } from "@olmokit/dom";
import { globalData } from "@olmokit/core/data";
import { DatalayerProject } from "components/Datalayer";
import gsap from "gsap";

/**
 * Component: ProjectFooter
 */
function TitleEnter() {
  const $container = $('.ProjectFooter:');
  let $title = $('#titlefooter', $container).textContent;  
  const $element = $('#elementfooter', $container);
  const $elementTwo = $('#elementfooter-two', $container);

  let theTitle = '';
  let theTitleTwo = '';

  if($title.includes('/')){
    $title = $title.split('/');
  }
  
  if(typeof $title == 'object'){
    forEach($title[0].toString(), (letter) => {
      theTitle += "<div>"+letter+"</div>";
    });
    forEach($title[1], (letter) => {
      theTitleTwo += "<div>"+letter+"</div>";
    });
  } else {
    forEach($title, (letter) => {
      const style = letter != ' ' ? '' : 'style="width: .4em"';
      theTitle += "<div "+style+">"+letter+"</div>";
    });
  }
  
  $element.innerHTML = theTitle;
  $elementTwo.innerHTML = theTitleTwo;  
}

function TitleOut() {
  const $container = $('.ProjectFooter:');
  let $title = $('#titlefooter', $container).textContent;  
  const $element = $('#elementfooter', $container);
  const $elementTwo = $('#elementfooter-two', $container);

  let theTitle = '';
  let theTitleTwo = '';

  if($title.includes('/')){
    $title = $title.split('/');
  }
  
  if(typeof $title == 'object'){
    forEach($title[0].toString(), (letter) => {
      theTitle += "<div>"+letter+"</div>";
    });
    forEach($title[1], (letter) => {
      theTitleTwo += "<div>"+letter+"</div>";
    });
  } else {
    forEach($title, (letter) => {
      const style = letter != ' ' ? '' : 'style="width: .4em"';
      theTitle += "<div "+style+">"+letter+"</div>";
    });
  }
  
  $element.innerHTML = theTitle;
  $elementTwo.innerHTML = theTitleTwo;  

  const $titleSpan = $all("div", $element);
  const $titleSpanTwo = $all("div", $elementTwo);       

  forEach($titleSpan, (span, index) => {
    gsap.set(span, {y: 0, opacity: 1});
    gsap.to(span, {duration: 0.3, y: -40, opacity: 0, delay: index * 0.03});
    // gsap.set(span, {y:-20});
    // gsap.to(span, {duration: 1, y:0, opacity: 1});
  });
  forEach($titleSpanTwo, (span, index) => {
    gsap.set(span, {y: 0, opacity: 1});
    gsap.to(span, {duration: 0.3, y: -40, opacity: 0, delay: index * 0.03});
    // gsap.set(span, {y:-20});
    // gsap.to(span, {duration: 1, y:0, opacity: 1});
  });  
}

export function ProjectFooter() {
  console.log("ProjectFooter mounted.");

  const $project = $('.ProjectFooter: a');
  on($project, 'click', () => {
    const action = $project.getAttribute('data-action');
    const label = $project.getAttribute('data-label');
    DatalayerProject(action, label);
  });

  if(globalData.route == 'projectsingle'){
    TitleEnter();
  }

  return {
    onAfter(data) {
      TitleEnter();
    },    
    onBefore(data) {
      TitleOut();
    },    
    onAfterProject(data) {
      TitleEnter();
    },    
    destroy() {}
  }
}
