/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "./index.scss";
import "layouts/main";
import { barbaRoute } from "@olmokit/core/barba/route";
import { ProjectMainSlider } from "components/Project/MainSlider";
import { ProjectRequest } from "components/Project/Request";
import { Letters } from "components/Service/Letters";
import { $ } from "@olmokit/dom";
import gsap from 'gsap';

console.log("Route home/index.js mounted.");

let components = [];

/**
 * Standard route hook.
 * Called on route's DOM insertion (after DOM rendering)
 */
function mounted() {

  components = components.concat([
    ProjectMainSlider(),
    ProjectRequest(),
    Letters(),
    ModelView()
  ]);

  try {

  } catch (e) { }
}

/**
 * Standard route hook.
 * Called on route's DOM deletion
 */
function destroyed() {
  components.forEach(({ destroy }) => destroy());
}

function ModelView() {
  const $model = $('.Gem:');
  gsap.to($model, {deolay: 1, duration: 0.5, autoAlpha: 1});
  // Handles loading the events for <model-viewer>'s slotted progress bar
  const onProgress = (event) => {
    const progressBar = event.target.querySelector('.progress-bar');
    const updatingBar = event.target.querySelector('.update-bar');
    updatingBar.style.width = `${event.detail.totalProgress * 100}%`;
    if (event.detail.totalProgress === 1) {
      progressBar.classList.add('hide');
      event.target.removeEventListener('progress', onProgress);
    } else {
      progressBar.classList.remove('hide');
    }
  };
  document.querySelector('model-viewer').addEventListener('progress', onProgress);  

  return {
    destroy(){}
  }
}

// Standard required export
export default barbaRoute(mounted, destroyed);
