import "components/Footer";
import "utils/btn.scss";
import "utils/fonts.scss";
import "utils/logo.scss";
import "@olmokit/core/debug/api";
import "@olmokit/core/grid/index.scss";
import "@olmokit/core/icon/index.scss";
import "@olmokit/core/img";
import LazyLoad from "@olmokit/core/lazy";
import "@olmokit/core/reboot/index.scss";
import "@olmokit/core/typography/reset.scss";
import "./index.scss";

// barba related imports
import { barba } from "@olmokit/core/barba";
import routes from "vendor/routesBarba";

import { $ } from "@olmokit/dom";

// scroll
import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

// datalayer
import { DatalayerPage } from "components/Datalayer";

// project
import { ProjectHeading } from "components/Project/Heading";
import { ProjectFooter } from "components/Project/Footer";
import { Header } from "components/Header";
import { Button } from "components/Button";
import { Footer } from "components/Footer";
import { Menu, MenuDatalayer } from "components/Menu";
import { Background } from "components/Background";

// Home animation
// import { Gem } from "components/Gem";

export const header = Header();
export const footer = Footer();
export const menu = Menu();
export const background = Background();

new LazyLoad();
Button();
MenuDatalayer();

/**
 * Barba global hooks
 *
 * Run at each state change, always re-initialise the lazy loading and update
 * the header state (which is outside of the `barba-container`)
 */
barba.hooks.before((data) => {
  if(data.next.namespace == "projectsingle" && data.current.namespace == "projectsingle"){
    const projectfooter = ProjectFooter();
    projectfooter.onBefore(data);
  }
});
barba.hooks.after((data) => {
  background.onAfter(data);
  header.onAfter(data);
  footer.onAfter(data);
  menu.onAfter(data);
  // if(data.current.namespace == "home"){
  //   const gem = Gem();
  //   gem.onAfter();
  // }
  if(data.next.namespace == "projectsingle"){
    const projectfooter = ProjectFooter();
    projectfooter.onAfter(data);
    const projectheading = ProjectHeading();
    projectheading.onAfter(data);
  }
});

barba.hooks.enter((data) => {
  window.scrollTo(0, 0);
  DatalayerPage().onAfter(data.next.namespace, data);
  new LazyLoad(data.next.container);
});

/**
* Barba init
*
* Choose global options, assign automatically generated views and define
* default and custom/conditional transitions
*/
barba.init({
  debug: true,
  // sync: true,
  timeout: 10000,
  preventRunning: true,
  views: routes(),
  prevent: ({ el }) => el.hasAttribute("data-locale") || el.classList.contains('bloglink'),   
  transitions: [
    {
      name: 'opacity-transition',      
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0,
          y: -20
        });
      },
      after(data) {
        gsap.set(data.next.container, {opacity: 0, y:-20});
        return gsap.to(data.next.container, {
          opacity: 1,
          y: 0
        });
      },       
      once(data) {
        gsap.set(data.next.container, {opacity: 0, y:-20});
        return gsap.to(data.next.container, {
          delay: 0.3,
          opacity: 1,
          y: 0
        });
      }
    },      
    {
      name: 'projectsingle-transition',
      from: {
        namespace: [
          'projectsingle'
        ]
      },
      to: {
        namespace: [
          'projectsingle'
        ]
      },
      leave(data) {
        const $footer = $('.Project:footer');
        return gsap.to(window, { duration: 1, scrollTo: $footer});
      }
    },      
    
  ],

});