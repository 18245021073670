import "./index.scss";
import { $, $all, forEach, on, addClass, removeClass } from "@olmokit/dom";
import gsap from "gsap";
import { device } from "@olmokit/core/detect";
import { DatalayerProject } from "components/Datalayer";

/**
 * Component: ProjectMainSlider
 */

function Slider() {

  const desktop = !device().any; 
  let scaleBigDesktop = 1, scaleSmallDesktop = 0.4;
  let scaleBigMobile = 1, scaleSmallMobile = 0.2;
  let opacityDesktop = 1, opacityMobile = 0;
  let scale = scaleBigDesktop;
  let scaleSmall = scaleSmallDesktop;
  let opacity = opacityDesktop;

  if(!desktop){
    scale = scaleBigMobile;
    scaleSmall = scaleSmallMobile;
    opacity = opacityMobile;
  }

  const options = {
    center: 2
  };  

  const width = $('.ProjectMainSlider: .container').offsetWidth;
  const singleWidth = width / 3;
  let current = options.center;

  const $container = $('.ProjectMainSlider:slider');
  $container.style.width = width + 'px';

  const $sliderWidth = $container.offsetWidth;
  const $singleSliderWidth = $sliderWidth / 3;
  const $containerTrack = $('.ProjectMainSlider:slider_track');
  const slidesNumber = parseInt($containerTrack.getAttribute('data-slides'));
  $containerTrack.style.width = $singleSliderWidth * (slidesNumber + 4) + 'px';

  const slides = $all('.ProjectMainSlider:slider_card');
  forEach(slides, (slide) => {
    slide.style.width = $singleSliderWidth + 'px'
  });

  /** Define the center */
  $containerTrack.style.transform = `translateX(${-$singleSliderWidth * current}px)`;

  /**
   * Setup classes
   */
  const $left = slides[options.center];
  const $center = slides[options.center + 1];
  const $right = slides[options.center + 2];  

  addClass($left, 'is-left');
  addClass($center, 'is-center');
  addClass($right, 'is-right');

  const $H2center = $('h2', $center);

  gsap.set($left, {autoAlpha: opacity});
  gsap.set($center, {scale: scale, autoAlpha: 1});
  gsap.set($right, {autoAlpha: opacity});
  gsap.set($H2center, {autoAlpha: 1});

  const $arrowRight = $('.ProjectMainSlider:slider_arrow-right');
  const $arrowLeft = $('.ProjectMainSlider:slider_arrow-left');

  on($arrowLeft, 'click', () => {    
    if (current <= 0) return;
    $containerTrack.style.transition = '500ms ease-in-out transform'
    current = current - 1;
    $containerTrack.style.transform = `translateX(${-$singleSliderWidth * current}px)`;

    /** Move the center slide */
    const $isCenter = $('.is-center');
    const $isLeft = $('.is-left');
    const $isRight = $('.is-right');
    removeClass($isLeft, 'is-left');
    removeClass($isCenter, 'is-center');
    removeClass($isRight, 'is-right');
    addClass(slides[current], 'is-left');
    addClass(slides[current + 1], 'is-center');
    addClass(slides[current + 2], 'is-right');    

    /**
     * Animation
     */
    // forEach(slides, (slide) => {
    //   slide.style.opacity = 0;
    //   slide.style.transform = 'scale(0.5)';
    // });
    const $H2left = $('h2', slides[current]);
    const $H2center = $('h2', slides[current + 1]);
    const $H2right = $('h2', slides[current + 2]);    
    gsap.to(slides[current], {scale: 0.4, autoAlpha: opacity});
    gsap.to(slides[current + 1], {scale: scale, autoAlpha: 1});
    gsap.to(slides[current + 2], {scale: 0.4, autoAlpha: opacity});
    gsap.to(slides[current + 3], {scale: 0.4, autoAlpha: 0});
    gsap.to($H2left, {autoAlpha: 0, duration: 0.3});
    gsap.to($H2center, {autoAlpha: 1, duration: 0.3});
    gsap.to($H2right, {autoAlpha: 0, duration: 0.3});
  }); 
   
  on($arrowRight, 'click', () => {
    if (current >= slides.length - 1) return;
    $containerTrack.style.transition = '500ms ease-in-out transform'
    current = current + 1;
    $containerTrack.style.transform = `translateX(${-$singleSliderWidth * current}px)`;    

    /** Move the center slide */
    const $isCenter = $('.is-center');
    const $isLeft = $('.is-left');
    const $isRight = $('.is-right');
    removeClass($isCenter, 'is-center');
    removeClass($isLeft, 'is-left');
    removeClass($isRight, 'is-right');
    addClass(slides[current], 'is-left');
    addClass(slides[current + 1], 'is-center');
    addClass(slides[current + 2], 'is-right');     

    /**
     * Animation
     */
    const $H2left = $('h2', slides[current]);
    const $H2center = $('h2', slides[current + 1]);
    const $H2right = $('h2', slides[current + 2]);    
    gsap.to(slides[current - 1], {scale: scaleSmall, autoAlpha: 0});
    gsap.to(slides[current], {scale: scaleSmall, autoAlpha: opacity});
    gsap.to(slides[current + 1], {scale: scale, autoAlpha: 1});
    gsap.to(slides[current + 2], {scale: scaleSmall, autoAlpha: opacity});
    gsap.to($H2left, {autoAlpha: 0, duration: 0.3});
    gsap.to($H2center, {autoAlpha: 1, duration: 0.3});
    gsap.to($H2right, {autoAlpha: 0, duration: 0.3});    
  });

  on($containerTrack, 'transitionend', () => {
    if (slides[current + 1].classList.contains('first_img')) {

      setTimeout(() => {
        $containerTrack.style.transition = 'none';
        current = slides.length - 4;
        $containerTrack.style.transform = `translateX(${-$singleSliderWidth * (current)}px)`;

        const $last_img = $('.first_img');
        gsap.set($last_img, {scale: scaleSmall});        
      
        const $isCenter = $('.is-center');
        const $isLeft = $('.is-left');
        const $isRight = $('.is-right');  
        removeClass($isCenter, 'is-center');
        removeClass($isLeft, 'is-left');
        removeClass($isRight, 'is-right');    
        addClass(slides[current], 'is-left');
        addClass(slides[current + 1], 'is-center');
        addClass(slides[current + 2], 'is-right');      

        /**
         * Animation
         */
        const $H2left = $('h2', slides[current]);
        const $H2center = $('h2', slides[current + 1]);
        const $H2right = $('h2', slides[current + 2]);    
        gsap.set(slides[current], {scale: scaleSmall, autoAlpha: opacity});
        gsap.set(slides[current + 1], {scale: scale, autoAlpha: 1});
        gsap.set(slides[current + 2], {scale: scaleSmall, autoAlpha: opacity});

        forEach(slides, (slide, index) => {
          if(index >= (current + 3)){
            gsap.set(slides[index], {scale: scaleSmall, autoAlpha: 0});
          }
        });        

        gsap.set($H2left, {autoAlpha: 0});
        gsap.set($H2center, {autoAlpha: 1});
        gsap.set($H2right, {autoAlpha: 0});

      }, 400);
  }  
  if (slides[current + 1].classList.contains('last_img')) {

    setTimeout(() => {
      $containerTrack.style.transition = 'none';
      current = slides.length - (current + 2);
      $containerTrack.style.transform = `translateX(${-$singleSliderWidth * (current)}px)`;    

      const $last_img = $('.last_img');
      gsap.set($last_img, {scale: scaleSmall});

      const $isCenter = $('.is-center');
      const $isLeft = $('.is-left');
      const $isRight = $('.is-right');      
      removeClass($isCenter, 'is-center');   
      removeClass($isLeft, 'is-left');
      removeClass($isRight, 'is-right');
      addClass(slides[current], 'is-left');
      addClass(slides[current + 1], 'is-center');
      addClass(slides[current + 2], 'is-right');      

      /**
       * Animation
       */
      const $H2left = $('h2', slides[current]);
      const $H2center = $('h2', slides[current + 1]);
      const $H2right = $('h2', slides[current + 2]);    
      gsap.set(slides[current], {scale: scaleSmall, autoAlpha: opacity});
      gsap.set(slides[current + 1], {scale: scale, autoAlpha: 1});
      gsap.set(slides[current + 2], {scale: scaleSmall, autoAlpha: opacity});

      forEach(slides, (slide, index) => {
        if(index >= (current + 3)){
          gsap.set(slides[index], {scale: scaleSmall, autoAlpha: 0});
        }
      });
      
      gsap.set($H2left, {autoAlpha: 0});
      gsap.set($H2center, {autoAlpha: 1});
      gsap.set($H2right, {autoAlpha: 0});

    }, 400);
  }
  });

  const $projectLinks = $all('.ProjectMainSlider: a');

  forEach($projectLinks, (link) => {
    on(link, 'click', () => {
      const action = link.getAttribute('data-action') 
      const label = link.getAttribute('data-label');
      DatalayerProject(action, label);
    });
  });

}

export function ProjectMainSlider() {
  console.log("ProjectMainSlider mounted.");   

  let slider = Slider();

  return {
    destroy(){
      slider = function(){};
    }
  }
}

/**
 * First image
 */

/**
 * Last image
 */
