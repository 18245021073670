export default `
<script id="fragmentShader" type="x-shader/x-fragment">
#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_resolution;
uniform float u_time;
uniform vec3 aColor;
uniform vec3 bColor;

void main(){
    vec2 st = gl_FragCoord.xy/u_resolution;
    float pct = 0.0;

    vec3 inside = aColor;
    vec3 outside = bColor;

    vec2 tC = vec2(0.5, 0.3)-st;
    pct = sqrt((tC.x)*(tC.x*10.0)+(tC.y/0.2)*(tC.y/0.4));	

    vec3 color = mix(inside, outside, pct);
    //vec3 color = vec3(st, cos(u_time));

    gl_FragColor = vec4( color, 1 );
}	
</script>
`;