import "./index.scss";
import gsap from "gsap";
import { $, $all, forEach, addClass, removeClass, on } from "@olmokit/dom";
import { DatalayerMenu } from "components/Datalayer";

/**
 * Component: Menu
 */
export function Menu() {
  console.log("Menu mounted."); 

  return {
    onAfter(data) {
      const $menu = $('.Menu:');
      if(data.next.namespace == 'blog' || data.next.namespace == 'blogsingle'){
        console.log('data', data);
        addClass($menu, 'black');
      } else {
        removeClass($menu, 'black');
      }
    }    
  }
}

export function MenuDatalayer() {
  const $menu = $all('.Menu: a');
  forEach($menu, (menu) => {
    on(menu, 'click', () => {
      const label = menu.getAttribute('data-label');
      DatalayerMenu(label);
    });    
  });   

  return {
    destroy() {

    }
  }
}

export function MenuAnimationIn() {

  const $menu = $(".Menu:");
  const $menulist = $all("li", $menu);
  const $main = $("main");
  const $footer = $(".Footer:");

  gsap.to($menu, {autoAlpha: 1, delay: 0.6});
  gsap.to($main, {autoAlpha: 0, y: -20});
  gsap.to($footer, {autoAlpha: 0, y: -20});

  forEach($menulist, (list) => {
    gsap.to(list, {y: 20, opacity: 1, delay: 0.6});    
  });  

}

export function MenuAnimationOut() {

  const $menu = $(".Menu:");
  const $menulist = $all("li", $menu);
  const $main = $("main");
  const $footer = $(".Footer:");
  
  gsap.to($menu, {autoAlpha: 0});
  gsap.to($main, {autoAlpha: 1, y: 0, delay: 0.6});
  gsap.to($footer, {autoAlpha: 1, y: 0});

  forEach($menulist, (list) => {
    gsap.to(list, {y: 0, opacity: 0});
  });

}
