import type { ITransitionData } from "@barba/core";
import { $all, forEach } from "@olmokit/dom";

/**
 * Swap route style
 */
function swapRoutesStyle(data: ITransitionData) {
  const nextId = data.next.namespace;
  if (process.env["NODE_ENV"] !== "production") {
    console.info("routesBarba: swapRoutesStyle, next id is:" + nextId);
  }

  // enable only the next route's style tag
  forEach($all("[data-route-style]"), $style => {
    if ($style.getAttribute("data-route-style") === nextId) {
      if (process.env["NODE_ENV"] !== "production") {
        console.info("routesBarba: enabling style for" + nextId);
      }
      $style.removeAttribute("type");
    } else {
      if (process.env["NODE_ENV"] !== "production") {
        console.info("routesBarba: disabling style for" + $style.id);
      }
      $style.setAttribute("type", "text");
    }
  });
}

/**
 * Routes definitions for barba-js
 */
export default function routesBarba() {
  const instances = {};

  function getInstanceSafely(id, module) {
    if (!instances[id]) {
      // class based routes:
      // instances[id] = new module.default({ id });
      // function based routes:
      instances[id] = module.default({ id });
    }
    return instances[id];
  }

  return [{
      namespace: "404",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: 404->beforeEnter", data);
        }
        import("routes/404").then((module) => {
          getInstanceSafely("404", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: 404->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/404/index.scss");
        }
        import("routes/404").then((module) => {
          getInstanceSafely("404", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: 404->afterLeave", data);
        }
        import("routes/404").then((module) => {
          getInstanceSafely("404", module).__d(data)
        });
      }
    },{
      namespace: "500",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: 500->beforeEnter", data);
        }
        import("routes/500").then((module) => {
          getInstanceSafely("500", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: 500->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/500/index.scss");
        }
        import("routes/500").then((module) => {
          getInstanceSafely("500", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: 500->afterLeave", data);
        }
        import("routes/500").then((module) => {
          getInstanceSafely("500", module).__d(data)
        });
      }
    },{
      namespace: "blog",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: blog->beforeEnter", data);
        }
        import("routes/blog").then((module) => {
          getInstanceSafely("blog", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: blog->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/blog/index.scss");
        }
        import("routes/blog").then((module) => {
          getInstanceSafely("blog", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: blog->afterLeave", data);
        }
        import("routes/blog").then((module) => {
          getInstanceSafely("blog", module).__d(data)
        });
      }
    },{
      namespace: "blogsingle",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: blogsingle->beforeEnter", data);
        }
        import("routes/blogsingle").then((module) => {
          getInstanceSafely("blogsingle", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: blogsingle->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/blogsingle/index.scss");
        }
        import("routes/blogsingle").then((module) => {
          getInstanceSafely("blogsingle", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: blogsingle->afterLeave", data);
        }
        import("routes/blogsingle").then((module) => {
          getInstanceSafely("blogsingle", module).__d(data)
        });
      }
    },{
      namespace: "contacts",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: contacts->beforeEnter", data);
        }
        import("routes/contacts").then((module) => {
          getInstanceSafely("contacts", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: contacts->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/contacts/index.scss");
        }
        import("routes/contacts").then((module) => {
          getInstanceSafely("contacts", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: contacts->afterLeave", data);
        }
        import("routes/contacts").then((module) => {
          getInstanceSafely("contacts", module).__d(data)
        });
      }
    },{
      namespace: "cookie-policy",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: cookie-policy->beforeEnter", data);
        }
        import("routes/cookie-policy").then((module) => {
          getInstanceSafely("cookie-policy", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: cookie-policy->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/cookie-policy/index.scss");
        }
        import("routes/cookie-policy").then((module) => {
          getInstanceSafely("cookie-policy", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: cookie-policy->afterLeave", data);
        }
        import("routes/cookie-policy").then((module) => {
          getInstanceSafely("cookie-policy", module).__d(data)
        });
      }
    },{
      namespace: "creative-agency",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: creative-agency->beforeEnter", data);
        }
        import("routes/creative-agency").then((module) => {
          getInstanceSafely("creative-agency", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: creative-agency->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/creative-agency/index.scss");
        }
        import("routes/creative-agency").then((module) => {
          getInstanceSafely("creative-agency", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: creative-agency->afterLeave", data);
        }
        import("routes/creative-agency").then((module) => {
          getInstanceSafely("creative-agency", module).__d(data)
        });
      }
    },{
      namespace: "home",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: home->beforeEnter", data);
        }
        import("routes/home").then((module) => {
          getInstanceSafely("home", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: home->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/home/index.scss");
        }
        import("routes/home").then((module) => {
          getInstanceSafely("home", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: home->afterLeave", data);
        }
        import("routes/home").then((module) => {
          getInstanceSafely("home", module).__d(data)
        });
      }
    },{
      namespace: "privacy-policy",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: privacy-policy->beforeEnter", data);
        }
        import("routes/privacy-policy").then((module) => {
          getInstanceSafely("privacy-policy", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: privacy-policy->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/privacy-policy/index.scss");
        }
        import("routes/privacy-policy").then((module) => {
          getInstanceSafely("privacy-policy", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: privacy-policy->afterLeave", data);
        }
        import("routes/privacy-policy").then((module) => {
          getInstanceSafely("privacy-policy", module).__d(data)
        });
      }
    },{
      namespace: "project",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: project->beforeEnter", data);
        }
        import("routes/project").then((module) => {
          getInstanceSafely("project", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: project->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/project/index.scss");
        }
        import("routes/project").then((module) => {
          getInstanceSafely("project", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: project->afterLeave", data);
        }
        import("routes/project").then((module) => {
          getInstanceSafely("project", module).__d(data)
        });
      }
    },{
      namespace: "projectsingle",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: projectsingle->beforeEnter", data);
        }
        import("routes/projectsingle").then((module) => {
          getInstanceSafely("projectsingle", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: projectsingle->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/projectsingle/index.scss");
        }
        import("routes/projectsingle").then((module) => {
          getInstanceSafely("projectsingle", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: projectsingle->afterLeave", data);
        }
        import("routes/projectsingle").then((module) => {
          getInstanceSafely("projectsingle", module).__d(data)
        });
      }
    },{
      namespace: "service",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: service->beforeEnter", data);
        }
        import("routes/service").then((module) => {
          getInstanceSafely("service", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: service->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/service/index.scss");
        }
        import("routes/service").then((module) => {
          getInstanceSafely("service", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: service->afterLeave", data);
        }
        import("routes/service").then((module) => {
          getInstanceSafely("service", module).__d(data)
        });
      }
    },{
      namespace: "servicesingle",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: servicesingle->beforeEnter", data);
        }
        import("routes/servicesingle").then((module) => {
          getInstanceSafely("servicesingle", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: servicesingle->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/servicesingle/index.scss");
        }
        import("routes/servicesingle").then((module) => {
          getInstanceSafely("servicesingle", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: servicesingle->afterLeave", data);
        }
        import("routes/servicesingle").then((module) => {
          getInstanceSafely("servicesingle", module).__d(data)
        });
      }
    },{
      namespace: "workwithus",
      beforeEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: workwithus->beforeEnter", data);
        }
        import("routes/workwithus").then((module) => {
          getInstanceSafely("workwithus", module);
        });
        
        swapRoutesStyle(data);
      },
      afterEnter(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: workwithus->afterEnter", data);
          // import scss too during dev as we are using style-loader...
          import("routes/workwithus/index.scss");
        }
        import("routes/workwithus").then((module) => {
          getInstanceSafely("workwithus", module).__m(data);
        });
      },
      afterLeave(data) {
        if (process.env["NODE_ENV"] !== "production") {
          console.log("routesBarba: workwithus->afterLeave", data);
        }
        import("routes/workwithus").then((module) => {
          getInstanceSafely("workwithus", module).__d(data)
        });
      }
    },  
  ];
}
