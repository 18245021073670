import "./index.scss";
import { $, $all, forEach } from "@olmokit/dom";
import { globalData } from "@olmokit/core/data";
import gsap from "gsap";

/**
 * Component: ProjectHeading
 */
function TitleEnter() {
  const $container = $('.ProjectHeading:');
  let $title = $('#title', $container).textContent;
  const $element = $('#element', $container);
  const $elementTwo = $('#element-two', $container);

  let theTitle = '';
  let theTitleTwo = '';

  if($title.includes('/')){
    $title = $title.split('/');
  }

  if(typeof $title == 'object'){
    forEach($title[0].toString(), (letter) => {
      theTitle += "<div>"+letter+"</div>";
    });
    forEach($title[1], (letter) => {
      theTitleTwo += "<div>"+letter+"</div>";
    });
  } else {
    forEach($title, (letter) => {
      theTitle += "<div>"+letter+"</div>";
    });
  }

  $element.innerHTML = theTitle;
  $elementTwo.innerHTML = theTitleTwo; 
}

export function ProjectHeading() {
  console.log("ProjectHeading mounted.");

  if(globalData.route == 'projectsingle'){

    TitleEnter();

    const $container = $('.ProjectHeading:');
    const $element = $('#element', $container);
    const $elementTwo = $('#element-two', $container);

    const $titleSpan = $all("div", $element);
    const $titleSpanTwo = $all("div", $elementTwo);     
  
    setTimeout(() => {
      forEach($titleSpan, (span, index) => {
        gsap.set(span, {perspective:500, transformStyle:"preserve-3d", rotationY: -50, rotationX: 30, y: -40});
        gsap.to(span, {duration: 1, rotationY: 0, rotationX: 0, y: 0, opacity: 1, delay: index * 0.03});
        if(span.textContent.trim() === '' ){
          span.style.width = '.3em';
        }
        // gsap.set(span, {y:-20});
        // gsap.to(span, {duration: 1, y:0, opacity: 1});
      });
      forEach($titleSpanTwo, (span, index) => {
        gsap.set(span, {perspective:500, transformStyle:"preserve-3d", rotationY: -50, rotationX: 30, y: -40});
        gsap.to(span, {duration: 1, rotationY: 0, rotationX: 0, y: 0, opacity: 1, delay: index * 0.03});
        if(span.textContent.trim() === '' ){
          span.style.width = '.3em';
        }        
        // gsap.set(span, {y:-20});
        // gsap.to(span, {duration: 1, y:0, opacity: 1});
      });
    }, 500);
  }

  return {
    onAfter(data) {
      TitleEnter();
      const $container = $('.ProjectHeading:');
      const $element = $('#element', $container);
      const $elementTwo = $('#element-two', $container);
      const $titleSpan = $all("div", $element);
      const $titleSpanTwo = $all("div", $elementTwo);

      forEach($titleSpan, (span, index) => {
        gsap.set(span, {perspective:500, transformStyle:"preserve-3d", rotationY: -50, rotationX: 30, y: -40});
        gsap.to(span, {duration: 1, rotationY: 0, rotationX: 0, y: 0, opacity: 1, delay: index * 0.03});
        if(span.textContent.trim() === '' ){
          span.style.width = '.3em';
        }
        // gsap.set(span, {y:-20});
        // gsap.to(span, {duration: 1, y:0, opacity: 1});
      });
      forEach($titleSpanTwo, (span, index) => {
        gsap.set(span, {perspective:500, transformStyle:"preserve-3d", rotationY: -50, rotationX: 30, y: -40});
        gsap.to(span, {duration: 1, rotationY: 0, rotationX: 0, y: 0, opacity: 1, delay: index * 0.03});
        if(span.textContent.trim() === '' ){
          span.style.width = '.3em';
        }        
        // gsap.set(span, {y:-20});
        // gsap.to(span, {duration: 1, y:0, opacity: 1});
      });      
      
    },
    onAfterProject(data) {
      const $title = $('h2');
      $title.style.display = 'block';
    },
    destroy() {}
  }
}
