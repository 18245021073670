import "./index.scss";
import { FormNewsletter } from "components/Form/Newsletter";
import { replaceFragment } from "@olmokit/core/fragments";
import { $, addClass, removeClass } from "@olmokit/dom";

// replaceFragment("product", "components.ProductDetail");
replaceFragment("contactNewseltter", "components.FormNewsletter").then(() => {FormNewsletter()});

export function Footer() {
    return {
        onAfter(data) {
          const $footer = $('.Footer:');
          if(data.next.namespace == 'blog' || data.next.namespace == 'blogsingle'){
            addClass($footer, 'black');
          } else {
            removeClass($footer, 'black');
          }
        }    
      }
}