import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
 * Component: ProjectRequest
 */
export function ProjectRequest() {
  console.log("ProjectRequest mounted.");

  return {
    destroy() {}
  }
}
