import { I18nLinks } from "@olmokit/core/i18n/links";
import { $, on, off, addClass, removeClass } from "@olmokit/dom";
import "components/Hamburger";
import { Selectlang } from "components/Selectlang";
import { MenuAnimationIn, MenuAnimationOut } from "components/Menu";
// import "utils/logo.scss";
import "./index.scss";

/**
 * Component: Header
 *
 */
export function Header() {
  // const header = BaseHeader();
  const i18nLinks = I18nLinks();
  // Selectlang();
  
  const $root = $('.Header:');
  if (!$root) {
    return;
  }

  const $toggle = $(".Header:toggle", $root);
  const $menu = $(".Menu:");

  /** @type {HeaderStatus} */
  let status = "collapsed";

  if ($toggle) {
    on($toggle, "click", handleToggle);
    on(document, "click", handleClickOutside);
  }

  /**
   * Handle click outside
   *
   * @param {MouseEvent} event
   */
  function handleClickOutside(event) {
    if (status === "expanded") {
      // @ts-ignore
      if (!$root.contains(event.target)) {
        collapse();
      }
    }
  }

  /**
   * Handle toggle
   */
  function handleToggle() {
    if (status !== "expanded") {
      expand();
    } else {
      collapse();
    }
  }

  /**
   * Expand
   *
   */
  function expand() {
    addClass($toggle, "is-active");
    addClass($menu, "is-active");
    MenuAnimationIn();
    _setStatus("expanded");
  }
  
  /**
   * Collapse
  *
  */
 function collapse() {
    removeClass($toggle, "is-active");
    removeClass($menu, "is-active");
    MenuAnimationOut();
    _setStatus("collapsed");
  }

  /**
   * Set status
   *
   * @param {HeaderStatus} newValue
   */
  function _setStatus(newValue) {
    status = newValue;
    $root.setAttribute("data-status", newValue);
  }

  /**
   * Destroy
   *
   */
  function destroy() {
    off($toggle, "click", handleToggle);
    off(document, "click", handleClickOutside);
  }  

  return {
    onAfter(data) {
      i18nLinks.onRouteChange();
      const $header = $('.Header:');
      const $logo = $('.logo path');
      const $hamburger = $('.Hamburger:inner');
      if(data.next.namespace == 'blog' || data.next.namespace == 'blogsingle'){
        $logo.setAttribute('fill', '#000')
        addClass($header, 'black');
        addClass($hamburger, 'black');
      } else {
        $logo.setAttribute('fill', '#fff')
        removeClass($header, 'black');
        removeClass($hamburger, 'black');
      }
      // destroy();
    }
  }

}
