import { device } from "@olmokit/core/detect";
import { $, $all, addClass, removeClass, forEach, on } from "@olmokit/dom";
import { DatalayerService } from "components/Datalayer";
import gsap from "gsap";
import "./index.scss";

/**
 * Component: Letters
 */
export function Letters() {
  console.log("Letters mounted.");  

  letterAnimation();

  const $projectCard = $all('.ServiceLetters: a');
  forEach($projectCard, (card) => {
    on(card, 'click', () => {
      const label = card.getAttribute('data-label');
      DatalayerService(label);
    });    
  });  

  return {
    destroy() {
      letterAnimation();
    },
  };  

}

function imageIconBackground() {

  const $container = $all('.ServiceLetters:single_image');
  const $images = $all('.ServiceLetters:single_image-letter');
  const $bg = $all('.ServiceLetters:single_image-bg');  

  forEach($container, (image, index) => {   

    image.addEventListener("mouseenter", function(  ) {
      if(!$bg[index].classList.contains('is-active')){
        addClass($bg[index], 'is-active');
        gsap.to($images[index], {transformStyle:"preserve-3d", rotationY: -50, rotationX: 30, y: -40, opacity: 0});
        gsap.to($bg[index], {opacity: 1, y: 0});
      }
    });
    image.addEventListener("mouseleave", function(  ) {
      if($bg[index].classList.contains('is-active')){
        removeClass($bg[index], 'is-active');
        gsap.to($images[index], {transformStyle:"preserve-3d", duration: 1, rotationY: 0, rotationX: 0, y: 0, opacity: 1});
        gsap.to($bg[index], {opacity: 0, y: 20});
      }
    });

  });

}

function imageBacground() {

  const $images = $all('.ServiceLetters:single_image-letter');
  const $bg = $all('.ServiceLetters:single_image-bg');
  const onMouseMove = (e) =>{
    forEach($images, (image, index) => {   
      
      const rect = image.getBoundingClientRect();

      image.addEventListener("mouseenter", function(  ) {
        if(!$bg[index].classList.contains('is-active')){
          addClass($bg[index], 'is-active');
          gsap.to(image, {rotationY: -50, rotationX: 30, y: -40, opacity: 0});          
        }
      });
      image.addEventListener("mouseout", function(  ) {
        if($bg[index].classList.contains('is-active')){
          removeClass($bg[index], 'is-active');
          gsap.to(image, {duration: 1, rotationY: 0, rotationX: 0, y: 0, opacity: 1});          
        }
      });

      if($bg[index].classList.contains('is-active')){
        gsap.to($bg[index], {left: (e.pageX - rect.left) + 100, top: (e.pageY - rect.top) + 50});
      }

    });
  }  
  document.addEventListener('mousemove', onMouseMove);

}

function inViewport( element ){
  const bb = element.getBoundingClientRect();
  const desktop = !device().any; 
  const offset = desktop ? 400 : 200 ;
  return !(bb.top > (innerHeight-offset) || bb.bottom < 0);
}

function singleLetterAnimation($letters, $shadows, $h3, $subtitle) {
  forEach($letters, (letter, index) => {
    if(inViewport(letter)){
      gsap.to(letter, {duration: 1, rotationY: 0, rotationX: 0, y: 0, opacity: 1});
      gsap.to($shadows[index], {duration: 1, opacity: 1});
      gsap.to($h3[index], {duration: 0.8, y: 0, opacity: 1, delay: 0.1});
      gsap.to($subtitle[index], {duration: 0.8, y: 0, opacity: 1, delay: 0.3});
    }
  });
}

function letterAnimation() {
  const $wrapper = $all(".ServiceLetters:single_image");  
  const $letters = $all(".ServiceLetters:single_image-letter");
  const $shadows = $all(".ServiceLetters:single_image-shadow");
  const $h3 = $all('.ServiceLetters:single_text h3');
  const $subtitle = $all('.ServiceLetters:single_text-subtitle');
  const $bg = $all('.ServiceLetters:single_image-bg');

  gsap.set($wrapper, {perspective:300});
  gsap.set($letters, {transformStyle:"preserve-3d", rotationY: -50, rotationX: 30, y: -40});
  gsap.set($bg, {y: 20});
  gsap.set($h3, {y: -20});
  gsap.set($subtitle, {y: -20});

  singleLetterAnimation($letters, $shadows, $h3, $subtitle);
  imageIconBackground();
  // imageBacground();

  window.addEventListener('scroll', function(e) {
    singleLetterAnimation($letters, $shadows, $h3, $subtitle);
  });
}